import { find } from 'lodash-es';

import { DomService, QuoteService, UtilsService } from '../../services';
import { FullWidthCarousel, Pagination } from '../../modules';
import { type Chart } from '../../modules/chart';
import { WebQuotesEvents } from '../../enums';
import type { Quote } from '../../models';

export class InstrumentPage {
	private quoteService: QuoteService;
	private priceElement: HTMLElement;
	private changeElement: HTMLElement;
	private readonly positiveClassName: string = 'positive';
	private readonly negativeClassName: string = 'negative';

	public instrumentData: {
		symbol: string;
		openValue: number;
		similarPageCount: string;
		currency?: string;
		conversionRate?: number;
		precision?: number;
		closedText?: string;
		tradingHours?: any;
	};

	public chart: Chart;

	public updateStatistics(price: number): void {}

	public initElements(): void {
		const symbolDataElement =
			DomService.getElement<HTMLElement>(`#symbol-data`);
		if (symbolDataElement?.textContent) {
			this.instrumentData = JSON.parse(symbolDataElement.textContent);
		}

		const priceElement =
			DomService.getElement<HTMLElement>(`.instrument__price`);
		if (priceElement) {
			this.priceElement = priceElement;
		}

		const changeElement =
			DomService.getElement<HTMLElement>(`.instrument__change`);
		if (changeElement) {
			this.changeElement = changeElement;
		}
	}

	public initBlogCarousel(): void {
		const blogWrapperElement = DomService.getElement<HTMLElement>(
			'.instrument__related-content'
		);

		if (!blogWrapperElement) {
			return;
		}

		const sliderContainer = DomService.getElement<HTMLElement>(
			'.related-articles__slider-wrapper',
			blogWrapperElement
		);

		if (sliderContainer) {
			const carousel = new FullWidthCarousel(
				blogWrapperElement,
				sliderContainer,
				'.related-articles__item'
			);
		}
	}

	public initSimilarInstrumentsPagination(defaultPageCount: number): void {
		let pageCount = defaultPageCount;
		const wrapper = DomService.getElement<HTMLElement>(
			'.similar-instruments__wrapper'
		);
		const item = DomService.getElement<HTMLElement>(
			'.instrument-tile__item',
			wrapper
		);

		if (wrapper && item) {
			pageCount = Math.floor(wrapper.clientWidth / item.clientWidth);
		}

		const pagination = new Pagination(
			'.instrument-tile__item',
			undefined,
			undefined,
			pageCount
		);
	}

	public initWebQuotes(): void {
		if (!this.instrumentData) {
			return;
		}

		this.quoteService = new QuoteService();
		this.quoteService
			.initConnection()
			.then(() => {
				this.quoteService.watchSymbols([this.instrumentData.symbol]);
			})
			.catch((error) => {
				console.log('error', error);
			});

		this.quoteService.subscribe(
			WebQuotesEvents.Quotes,
			(quotes: Quote[]) => {
				if (this.priceElement && quotes.length) {
					let price;
					let time;

					// TODO: remove after backend fix this behavior (quotes for not subscribed symbols)
					// on init symbol subscribe backend sends first message with quotes for all symbols
					if (quotes.length > 1) {
						const symbolQuote = find(quotes, {
							symbol: this.instrumentData.symbol
						});

						if (symbolQuote) {
							price = symbolQuote.bid;
							time = symbolQuote.time;
						}
					} else {
						price = quotes[0].bid;
						time = quotes[0].time;
					}

					if (!price || !time) {
						return;
					}

					this.priceElement.innerText = UtilsService.formatPriceValue(
						price,
						this.instrumentData.currency
					);

					if (this.instrumentData.openValue) {
						const percentageChange =
							(price * 100) / this.instrumentData.openValue - 100;
						const points = price - this.instrumentData.openValue;
						this.updateDailyChange(percentageChange, points);
					}

					this.updateCandle(price);
					this.updateStatistics(price);
				}
			}
		);
	}

	public updateDailyChange(change: number, points: number): void {
		const isPositiveChange = change >= 0;
		this.changeElement.innerText = `${
			isPositiveChange ? '+' : ''
		}${points.toFixed(this.instrumentData?.precision)} (${
			isPositiveChange ? '+' : ''
		}${change.toFixed(2)}%)`;

		const elementClassList = this.changeElement.classList;

		if (isPositiveChange) {
			if (elementClassList.contains(this.negativeClassName)) {
				elementClassList.remove(this.negativeClassName);
			}
			elementClassList.add(this.positiveClassName);
		} else {
			if (elementClassList.contains(this.positiveClassName)) {
				elementClassList.remove(this.positiveClassName);
			}
			elementClassList.add(this.negativeClassName);
		}
	}

	public updateCandle(price: number): void {
		if (this.chart) {
			this.chart.updateCandle(price);
		}
	}
}
