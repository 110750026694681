import { difference, forEach, isNil, keys } from 'lodash-es';

import {
	CandleService,
	DomService,
	type ISessionChange,
	QuoteService,
	UtilsService
} from '../../services';
import { WebQuotesEvents } from '../../enums';
import { type Quote } from '../../models';

export class InstrumentsCollectionQuotes {
	private readonly isIntersectionObserverAvailable: boolean = false;
	private observer: IntersectionObserver;

	private quoteService: QuoteService;
	private readonly candleService: CandleService;
	private tableSymbols: Array<string> = [];
	private tableInstrumentElements: Record<
		string,
		{
			currency?: string;
			price: HTMLElement;
			change: HTMLElement;
		}
	> = {};

	private openValues: Record<string, number> = {};
	private sessionChange: Record<string, number> = {};
	private lastPrices: Record<string, Record<string, number>> = {};

	private readonly positiveClassName: string = 'positive';
	private readonly negativeClassName: string = 'negative';

	constructor(
		private readonly selector: string = '.instruments-table__item',
		private readonly tableSelector: string = '.instruments-table',
		private elementsNode?: HTMLElement | Element
	) {
		this.isIntersectionObserverAvailable = 'IntersectionObserver' in window;
		this.candleService = new CandleService();

		this.initElementsForQuotes();
		this.initObserver();
	}

	private initElementsForQuotes(): void {
		const instrumentElements = DomService.getElements<HTMLElement>(
			this.selector,
			this.elementsNode
		);
		if (instrumentElements) {
			instrumentElements.forEach((element: HTMLElement) => {
				const symbolName = element.dataset.symbol?.toUpperCase();

				const priceElement = DomService.getElement<HTMLElement>(
					`.instrument-price`,
					element
				);
				const changeElement = DomService.getElement<HTMLElement>(
					`.instrument-change`,
					element
				);

				if (!symbolName || !priceElement || !changeElement) {
					return;
				}

				this.tableSymbols.push(symbolName);
				this.tableInstrumentElements[symbolName] = {
					price: priceElement,
					change: changeElement
				};
			});
		}
	}

	private initObserver(): void {
		if (this.isIntersectionObserverAvailable) {
			const tableElement = DomService.getElement<HTMLElement>(
				this.tableSelector,
				this.elementsNode
			);
			if (!tableElement) {
				return;
			}

			const observerOptions = {
				threshold: 0
			};

			const observerCallback = (
				entries: IntersectionObserverEntry[]
			): void => {
				entries.forEach((entry: IntersectionObserverEntry) => {
					if (entry.isIntersecting) {
						this.initDailyChange();
						this.initWebQuotes();
						this.observer.disconnect();
					}
				});
			};

			this.observer = new IntersectionObserver(
				observerCallback,
				observerOptions
			);

			this.observer.observe(tableElement);
		} else {
			setTimeout(() => {
				this.initDailyChange();
				this.initWebQuotes();
			}, 2000); //  init quotes after 2s to avoid "uninterested" users and not overload Quotes server
		}
	}

	private initWebQuotes(): void {
		if (!this.tableSymbols.length) {
			return;
		}

		this.quoteService = new QuoteService();
		this.quoteService
			.initConnection()
			.then(() => {
				this.quoteService.watchSymbols(this.tableSymbols);
			})
			.catch((error) => {
				console.log('error', error);
			});

		this.quoteService.subscribe(
			WebQuotesEvents.Quotes,
			(quotes: Quote[]) => {
				if (quotes.length) {
					quotes.forEach((quote: Quote) => {
						if (this.tableInstrumentElements[quote.symbol]) {
							this.updateRowValues(quote.symbol, quote.bid);

							const openValue = this.openValues[quote.symbol];
							if (openValue) {
								const percentageChange =
									(quote.bid * 100) / openValue - 100;
								this.sessionChange[quote.symbol] =
									percentageChange;
								this.updateDailyChange(
									quote.symbol,
									percentageChange
								);
							}
						}
					});
				}
			}
		);
	}

	private updateRowValues(symbol: string, price: number): void {
		const element = 'price';
		if (!this.lastPrices[element]) {
			this.lastPrices[element] = {};
		}
		this.lastPrices[element][symbol] = price;
		this.tableInstrumentElements[symbol].price.innerText =
			UtilsService.formatPriceValue(price);
	}

	private updateDailyChange(symbol: string, change: number): void {
		const isPositiveChange = change >= 0;
		this.tableInstrumentElements[symbol].change.innerText = `${
			isPositiveChange ? '+' : ''
		}${change.toFixed(2)}%`;
		const elementClassList =
			this.tableInstrumentElements[symbol].change.classList;

		if (isPositiveChange) {
			if (elementClassList.contains(this.negativeClassName)) {
				elementClassList.remove(this.negativeClassName);
			}
			elementClassList.add(this.positiveClassName);
		} else {
			if (elementClassList.contains(this.positiveClassName)) {
				elementClassList.remove(this.positiveClassName);
			}
			elementClassList.add(this.negativeClassName);
		}
	}

	private initDailyChange(): void {
		const symbolsToFetchCandle = difference(
			this.tableSymbols,
			keys(this.openValues)
		);
		const symbolsToUpdateData = difference(
			this.tableSymbols,
			symbolsToFetchCandle
		);

		if (symbolsToUpdateData.length) {
			forEach(symbolsToUpdateData, (symbol: string) => {
				const symbolChange = this.sessionChange[symbol];

				if (
					!isNil(symbolChange) &&
					this.tableInstrumentElements[symbol]
				) {
					this.updateDailyChange(symbol, symbolChange);
				}
			});
		}

		if (!symbolsToFetchCandle.length) {
			return;
		}

		this.candleService
			.getDailyChange(symbolsToFetchCandle)
			.then((sessionChanges: Record<string, ISessionChange>) => {
				forEach(keys(sessionChanges), (symbol: string) => {
					const symbolSessionChange = sessionChanges[symbol];

					if (this.tableInstrumentElements[symbol]) {
						this.sessionChange[symbol] = symbolSessionChange.change;
						this.openValues[symbol] = symbolSessionChange.openValue;
						this.updateDailyChange(
							symbol,
							symbolSessionChange.change
						);
					}
				});
			})
			.catch((error) => {
				console.log('error', error);
			});
	}

	public updateElements(targetElement?: HTMLElement | null): void {
		if (targetElement) {
			this.elementsNode = targetElement;
		}

		this.quoteService.unwatchSymbols(this.tableSymbols);

		this.tableSymbols = [];
		this.initElementsForQuotes();

		this.initDailyChange();

		this.quoteService.watchSymbols(this.tableSymbols);
	}
}
